// Firebase
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, increment, serverTimestamp } from "firebase/firestore";
import { firebaseStorage, fireStoreDB } from '../../Firebase';


export const uploadImage = (imagedata, shopid, imagename) => {

  console.log('onUploadImage():', imagedata, shopid, imagename);

  if (imagedata === undefined) {
    console.log("onUploadImage(): イメージが未定義");
    return;
  }

  console.log(imagedata);

  // アップロード処理
  const storageRef = ref(firebaseStorage, 'shops/'+shopid+'/submit/'+imagename);

  const metadata = {
    contentType: 'image/jpeg',
  };

  // 'file' comes from the Blob or File API
  const uploadTask = uploadBytesResumable(storageRef, imagedata, metadata);

  uploadTask.on('state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      // eslint-disable-next-line default-case
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      // Handle unsuccessful uploads
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    }
  );
}

// 申請メニュー情報をDBに書き込み
export const getNewMenuid = async (shopid) => {
  console.log('getNewMenuid() with shopid:', shopid);

  const ref = doc(fireStoreDB, "submitmenus", shopid);
  const docSnap = await getDoc(ref);
  if (! docSnap.exists()) {
    console.log("No menudata@shopid:", shopid);

    setDoc(doc(fireStoreDB, "submitmenus", shopid),
      { sequence: 0, theMenuList: [] });
  } else {
    console.log("exist menudata@shopid:", shopid);
  }

  await updateDoc(ref, {
    sequence: increment(1)
    })
    .catch(function(error) {
      console.log(`update error@!getNewMenuid(): ${error}`);
    });

  const docSnap2 = await getDoc(ref);
  if (! docSnap2.exists()) {
    console.log(`get newid error@!getNewMenuid()`);
    return "";
  }

  const data = docSnap2.data();
  const menuid = shopid+data.sequence.toString().padStart(5, '0');
  console.log("new menuid:", menuid);

  return menuid;

}

// 申請メニューレコードを新規作成
export const addMenuData = async (data, shopid, menuid) => {

  if (data === undefined) {
    console.log("addMenuData(): イメージが未定義");
    return;
  }

  console.log('addMenuData():', data);

  data.menuid = menuid;
  updateDoc(doc(fireStoreDB, "submitmenus", shopid),
    { theMenuList: arrayUnion( data ) }
  // { theMenuList: arrayUnion( {...data, ...{timestamp :serverTimestamp()}} ) }
  );
  console.log("addMenuData add array with shopid:", shopid);

}

// 申請メニューレコードを既存更新
export const updateMenuData = async (data, shopid, menuid) => {

  if (data === undefined) {
    console.log("updateMenuData(): イメージが未定義");
    return;
  }

  console.log('updateMenuData():', data);

  // 既存レコード検索＆削除
  const ref = doc(fireStoreDB, "submitmenus", shopid);
  getDoc(ref).then((docSnap) => {
    const menus = docSnap.data().theMenuList;
    menus.forEach(menu => {
      if (menu.menuid === menuid) {
        console.log("Remove menu @menuid:", menuid);

        updateDoc(doc(fireStoreDB, "submitmenus", shopid),
          { theMenuList: arrayRemove(menu) }
        );
      }
    });

  // 更新レコード追加
  updateDoc(doc(fireStoreDB, "submitmenus", shopid),
    { theMenuList: arrayUnion( data ) }
    // { theMenuList: arrayUnion( {...data, ...{timestamp :serverTimestamp()}} ) }
    );
    console.log("Add menu @menuid:", menuid);

  });

}


// 申請メニュー情報をDBに書き込み
const SubmitMenuPerform = (data) => {

  console.log('Submit data:', data);
  //submitMenuData(data);

};
export default SubmitMenuPerform;

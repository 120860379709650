import React, { useState, useEffect, }  from 'react';

import { styled } from '@mui/system';


import { Grid, Box } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { Button, ToggleButtonGroup, ToggleButton } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Rating from '@mui/material/Rating';
import dayjs from 'dayjs';

import ResponsiveDrawer from '../atoms/ResponsiveDrawer'
import { drawerWidth } from '../atoms/ResponsiveDrawer';


// For Firebase Realtime DB
import { ref, onValue, toDate } from 'firebase/database';
import { firebaseDB } from '../../Firebase';


//#################################
const shopid = 'shopid0001';


const Review = () => {

  // 初回レンダリング完了時に実行
  // コンポーネント初期化
  useEffect(() => {

    // Firesbase Realtime DBからデータを読み込む
    getFirebaseDB(shopid);

  }, []);


  // レビューデータ定義
  // from TricoApp
  // struct ReviewDataPresentable: Equatable, Codable {
  //   var id: String
  //   var shopid: String
  //   var shopName: String
  //   var rating: Float
  //   var userName: String
  //   var userid: String
  //   var title: String
  //   var discription: String
  //   var updateDate: Date
  // }

  // データ定義
  const setMenu = (updateDate, oderid, rating, discription) => {
    return {
      updateDate, oderid, rating, discription,
    }
  }

  var rows = [
    // サンプルデータ
    setMenu('2021-12-31', '01FRM754H58WV', 3.0, '神戸で愛されてきたTanaのリストランテとピッツェリアを融合させた旗艦店。\n400℃の石窯で焼き上げる、焼き立てもっちりの【89ナポリピッツァ】をはじめとする本格イタリアンを、海をイメージしたオシャレな店内や開放的なテラスでどうぞ。生簀から調理する名物【活渡り蟹の手打ちパスタ】や新鮮な魚介を丸ごと調理した【アクアパッツァ】など海鮮料理も人気です。またお得な飲み放題付コースも各種ご用意。ワンランク上の女子会や各種ご宴会にぜひご利用ください。',),
    setMenu('2022-01-12', '01FRM9H7HN1VV', 1.0, '神戸で愛されてきたTanaのリストランテとピッツェリアを融合させた旗艦店。\n400℃の石窯で焼き上げる、焼き立てもっちりの【89ナポリピッツァ】をはじめとする本格イタリアンを、海をイメージしたオシャレな店内や開放的なテラスでどうぞ。生簀から調理する名物【活渡り蟹の手打ちパスタ】や新鮮な魚介を丸ごと調理した【アクアパッツァ】など海鮮料理も人気です。またお得な飲み放題付コースも各種ご用意。ワンランク上の女子会や各種ご宴会にぜひご利用ください。',),
    setMenu('2022-01-31', '01FS461Q570BM', 2.0, '神戸で愛されてきたTanaのリストランテとピッツェリアを融合させた旗艦店。\n400℃の石窯で焼き上げる、焼き立てもっちりの【89ナポリピッツァ】をはじめとする本格イタリアンを、海をイメージしたオシャレな店内や開放的なテラスでどうぞ。生簀から調理する名物【活渡り蟹の手打ちパスタ】や新鮮な魚介を丸ごと調理した【アクアパッツァ】など海鮮料理も人気です。またお得な飲み放題付コースも各種ご用意。ワンランク上の女子会や各種ご宴会にぜひご利用ください。',),
    // setMenu('2021-12-31', '01FRM754H58WVMTK8J4QPQAK7V', 3.0, '神戸で愛されてきたTanaのリストランテとピッツェリアを融合させた旗艦店。\n400℃の石窯で焼き上げる、焼き立てもっちりの【89ナポリピッツァ】をはじめとする本格イタリアンを、海をイメージしたオシャレな店内や開放的なテラスでどうぞ。生簀から調理する名物【活渡り蟹の手打ちパスタ】や新鮮な魚介を丸ごと調理した【アクアパッツァ】など海鮮料理も人気です。またお得な飲み放題付コースも各種ご用意。ワンランク上の女子会や各種ご宴会にぜひご利用ください。',),
    // setMenu('2022-01-12', '01FRM9H7HN1VVQN4G5M88B81FB', 1.0, '神戸で愛されてきたTanaのリストランテとピッツェリアを融合させた旗艦店。\n400℃の石窯で焼き上げる、焼き立てもっちりの【89ナポリピッツァ】をはじめとする本格イタリアンを、海をイメージしたオシャレな店内や開放的なテラスでどうぞ。生簀から調理する名物【活渡り蟹の手打ちパスタ】や新鮮な魚介を丸ごと調理した【アクアパッツァ】など海鮮料理も人気です。またお得な飲み放題付コースも各種ご用意。ワンランク上の女子会や各種ご宴会にぜひご利用ください。',),
    // setMenu('2022-01-31', '01FS461Q570BMBDKBTQKDR42RE', 2.0, '神戸で愛されてきたTanaのリストランテとピッツェリアを融合させた旗艦店。\n400℃の石窯で焼き上げる、焼き立てもっちりの【89ナポリピッツァ】をはじめとする本格イタリアンを、海をイメージしたオシャレな店内や開放的なテラスでどうぞ。生簀から調理する名物【活渡り蟹の手打ちパスタ】や新鮮な魚介を丸ごと調理した【アクアパッツァ】など海鮮料理も人気です。またお得な飲み放題付コースも各種ご用意。ワンランク上の女子会や各種ご宴会にぜひご利用ください。',),
  ];
  const [reviewData, setRevieData] = useState(rows)

  // ビュータイプ設定 'overall' or 'lsit'
  const [viewType, setViewType] = useState('list')

  // 各ボタンクリック検知ハンドラー
  const handleSwitchType = (event, type) => {
    console.log('handleSwitchType() with type:', type);
    setViewType(type);
  };

  // Firebaseからのデータ取得
  const getFirebaseDB = async (shopid) => {


    const dbRef = ref(firebaseDB, 'Reviews/');
    //#TODO
    //const starCountRef = ref(realtimedb, 'Orders/');
    onValue(dbRef, snapshot => {

      if (snapshot) {
        const dataList = snapshot.val();
        //const key = snapshot.key;
        let arrary = [];
        // データオブジェクトを配列に変更する
        if(dataList != null) {
          Object.keys(dataList).forEach((val, key) => {
              dataList[val].id = val;
              arrary.push(dataList[val]);
          })
        }
        console.log(arrary);
        // setRevieData({ rows: arrary });
        setRevieData(arrary);
     }

    });

  }

  const styles = theme => ({

    //const styles = StyleSheet.create({
      textFld: { width: 200, height: 40}   //assign the width as your requirement
  });


  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const createSortHandler = (property) => (event) => {
    // onRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <ResponsiveDrawer>
      <div>
        {/* <p>TOP</p> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'center',
            '& > *': {
              m: 2,
            },
            height: 500,
            width: 910-drawerWidth
          }}
        >
          <Stack spacing={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height:60,
                '& > *': {
                  m: 10,
                },
              }}
            >
              <ToggleButtonGroup
                value={viewType}
                exclusive
                onChange={handleSwitchType}
                size="large"
                color="standard"
                aria-label="large button group">
                <ToggleButton
                  value='overall'
                  variant='contained'
                  sx={{
                    width: 250,
                  }}
                  >
                  全体
                  </ToggleButton>
                  <ToggleButton
                  value='list'
                  variant='contained'
                  sx={{
                    width: 250,
                  }}
                  >
                  レビュー一覧
                  </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: 910-drawerWidth,
                '& > *': {
                  m: 1,
                },
              }}
            >
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">No.</TableCell> */}
                      <TableCell align="center" style={{ width: 150 }}>レビュー日</TableCell>
                      <TableCell align="center" style={{ width: 80 }}>注文No.</TableCell>

                      <TableCell align="center" style={{ width: 100 }}>レート</TableCell>
                      {/* <TableSortLabel
                        active
                        direction={order}
                        onClick={createSortHandler("value")}
                      >
                        レート
                      </TableSortLabel> */}
                      <TableCell align="center" style={{ width: 600 }}>レビュー</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reviewData.map((row) => (
                      <TableRow
                        key={row.oderid}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center">{dayjs(Date(row.updateDate)).format('YYYY-MM-DD')}</TableCell>
                        <TableCell align="center"
                          style={{
                            width: 80,
                            whiteSpace: 'normal',
                            wordWrap: 'break-word'}}
                          >
                          {row.oderid}
                        </TableCell>
                        <TableCell align="center">
                          <Rating name="read-only" value={row.rating} readOnly />
                        </TableCell>
                        <TableCell align="left">{row.discription}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            </Stack>

        </Box>
      </div>
    </ResponsiveDrawer>
  )

}

export default Review;


import React, { useState, useEffect, }  from 'react';

import { Paper, Box, Stack } from '@mui/material';
import { Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { FormControlLabel, Switch } from '@mui/material';

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import TimePicker from '@mui/lab/TimePicker';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

import ResponsiveDrawer from '../atoms/ResponsiveDrawer';

// firebaseのインポート
import { getDatabase, ref, onValue, push, child, set, serverTimestamp} from "firebase/database";


export default function ShopHourSetting() {

  const hhmm2val = (hh, mm) => hh*60+mm
  const val2hhmm = value => String(Math.floor(value/60)).padStart(2,'0')+':'+String(value%60).padStart(2,'0')

  // 初回レンダリング完了時に実行
  // コンポーネント初期化
  useEffect(() => {

    // DBからデータを読み込み&'Mon'を選択する
    getRealtimeDB();

  }, []);

  // 設定データ定義
  function settingData(day, businessDayFlag, startTime, endTime, comment = "") {
    return {
      day,businessDayFlag,startTime, endTime, comment,
      breakTimes: [
        {
          startTime: hhmm2val(10,0),
          endTime: hhmm2val(11,0),
          comment: 'お昼休憩',
        },
        {
          startTime: hhmm2val(15,0),
          endTime:  hhmm2val(18,0),
          comment: '夕方休憩',
        },
      ],
    };
  }

  var settingsData = [
    // settingData('Mon', false, hhmm2val(7,0), hhmm2val(21,0)),
    // settingData('Tue', true, hhmm2val(8,0), hhmm2val(20,0)),
    // settingData('Wed', true, hhmm2val(9,0), hhmm2val(19,0)),
    // settingData('Thu', true, hhmm2val(10,0), hhmm2val(18,0)),
    // settingData('Fri', true, hhmm2val(11,0), hhmm2val(17,0)),
    // settingData('Sat', true, hhmm2val(12,0), hhmm2val(16,0)),
    // settingData('Sun', true, hhmm2val(13,0), hhmm2val(15,0)),
  ];

  const [settings, setSettings] = useState(settingsData)


  //クリックハンドラー
  const onClickHandler = (index) => {

    //console.log(index)

    let setting = settings[index]
    //console.log(setting)

    setSwitchValue(setting.businessDayFlag)
    setSliderValues([setting.startTime, setting.endTime]);

  };

  const labelOfTheDayOfWeek = {
    'Mon': '月',
    'Tue': '火',
    'Wed': '水',
    'Thu': '木',
    'Fri': '金',
    'Sat': '土',
    'Sun': '日',
  }

  const buttons = [
    settings.map((setting, index) => (
      (labelOfTheDayOfWeek[setting.day]) ?
      <ToggleButton
        value={setting.day}
        onClick={() => onClickHandler(index)}
        variant='contained'
      >
        {labelOfTheDayOfWeek[setting.day]}
      </ToggleButton>
      : null
    ))
  ];


  // 曜日設定
  const [day, setDay] = useState('Mon')
  const handleDayOfWeek = (event, day) => {
    setDay(day);
    console.log(day);
  };


  // Switch定義
  const [switchValue, setSwitchValue] = useState(true)

  const handleSwitchChange = (event, newValue) => {

    setSwitchValue(newValue)

    let index = settings.findIndex((s) => s.day === day);

    settings[index].businessDayFlag = newValue;
    setSettings(settings)

  };


  // Slider定義
  const minDistance = 30;

  //const unixTime2javaScriptTime = value => value*1000

  const startOfDay = hhmm2val(7,0)
  const endOfDay   = hhmm2val(25,0)

  const startTime = hhmm2val(9,0)
  const endTime   = hhmm2val(19,0)

  const [sliderValues, setSliderValues] = useState([startTime,endTime])


  const handleSliderChange = (event, newValue, activeThumb) => {

    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setSliderValues([Math.min(newValue[0], sliderValues[1] - minDistance), sliderValues[1]]);
    } else {
      setSliderValues([sliderValues[0], Math.max(newValue[1], sliderValues[0] + minDistance)]);
    }

    let index = settings.findIndex((s) => s.day === day)
    //console.log(settings[index])

    settings[index].startTime = newValue[0]
    settings[index].endTime = newValue[1]

    setSettings(settings)

  };

  const marks = [
    { value: hhmm2val(7,0), label: '7:00', },
    { value: hhmm2val(10,0),label: '10:00', },
    { value: hhmm2val(13,0),label: '13:00', },
    { value: hhmm2val(16,0),label: '16:00', },
    { value: hhmm2val(19,0),label: '19:00', },
    { value: hhmm2val(22,0),label: '22:00', },
    { value: hhmm2val(25,0),label: '25:00', },
  ];

  // const [value2, setValue2] = useState([20, 37]);
  // const handleSliderChange2 = (event, newValue) => {
  //   setValue2(newValue);
  // };

  const shopid = 'shopid0001';

  // Firebaseから最新データを取得
  const getRealtimeDB = () => {

    const realtimedb = getDatabase();
    const dbRef = ref(realtimedb, 'ShopHours/'+shopid+'/');

    onValue(dbRef, snapshot => {

      if (snapshot) {
        const dataList = snapshot.val();
        //const key = snapshot.key;
        let arrary = [];
        // データオブジェクトを配列に変更する
        if(dataList != null) {
          console.log("getRealtimeDB:")
          Object.keys(dataList).forEach((val, key) => {
              //console.log(val, key)
              dataList[val].id = val;
              arrary.push(dataList[val]);
          })
        }
        //console.log(arrary);
        setSettings(arrary);

        // 初回は月を選択
        setDay('Mon');
        var d = arrary.find((d) => d.day === day);
        if (d) {
          console.log(d);
          setSwitchValue(d.businessDayFlag)
          setSliderValues([d.startTime, d.endTime]);
        }

     }

    });
  }

  // Firebaseへデータ書き込み
  const putRealtimeDB = (data) => {

    const realtimedb = getDatabase();
    const newKey = push(child(ref(realtimedb), 'posts')).key;
    //set(ref(realtimedb, 'ShopHours/'+shopid+'/'+newKey), data);
    set(ref(realtimedb, 'ShopHours/'+shopid+'/'), data);

  };

  const handleUpdate = () => {

    let shopHours = settings.find((s) => s.day === day);
    if (shopHours) {
      console.log(shopHours);
      //putRealtimeDB(shopHours);
      putRealtimeDB(settings);
    }

  };


  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const ariaLabel = { 'aria-label': 'description' };


  return (
    <ResponsiveDrawer>
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *': {
            m: 1,
          },
        }}
      >
        <ToggleButtonGroup
          value={day}
          exclusive
          onChange={handleDayOfWeek}
          size="large"
          color="standard"
          aria-label="large button group">
          {buttons}
        </ToggleButtonGroup>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
              m: 1,
            },
            width: 600
          }}
        >
          <Slider
            getAriaLabel={() => 'Minimum distance'}
            value={sliderValues}
            disabled={!switchValue}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            getAriaValueText={val2hhmm}
            valueLabelFormat={val2hhmm}
            disableSwap
            min={startOfDay}
            max={endOfDay}
            marks={marks}
            step={30}
          />
        </Box>

        {/* <Box sx={{ width: 600 }}>
          <Slider
            aria-label="Custom marks"
            defaultValue={20}
            onChange={handleSliderChange}
            getAriaValueText={val2hhmm}
            step={10}
            valueLabelDisplay="auto"
          />
        </Box> */}

        <Box
          component="span"
          m={1}
          sx={{
            box: {
              width: 600,
              height: 500,
              display: "flex",
              border: "1px solid black",
              padding: 8
            },
            spreadBox: {
              justifyContent: "space-around",
              alignItems: "center"
            }
          }}
        >
          <Stack direction="row" spacing={10}>
            <FormControlLabel
              control={<Switch
                        checked={switchValue}
                        onChange={handleSwitchChange}
              />}
              label="営業日"
            />
            <Item>
              <TextField
                id="opentime"
                label="開始"
                type="time"
                defaultValue="07:30"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 30*60, // 5 min
                }}
                value={val2hhmm(sliderValues[0])}
                sx={{ width: 150 }}
              />
            </Item>

            <Item>
              <TextField
                id="closetime"
                label="終了"
                type="text"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 30*60, // 5 min
                }}
                value={ val2hhmm(sliderValues[1]) }
                sx={{ width: 150 }}
              />
            </Item>
          </Stack>
        </Box>
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 100,
          height: 200,
          align: 'right',
          '& > *': {
            m: 1,
          },
        }}
        >
          <Button variant="contained"
                  onClick={handleUpdate}
          >更新</Button>
        </Box>
      </Box>
    </div>
    </ResponsiveDrawer>

  );
}

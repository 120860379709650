import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { Paper, Box, Stack } from '@mui/material';
import { Button, ToggleButtonGroup, ToggleButton } from '@mui/material';

import { styled } from '@mui/material/styles';

// Slider&Switch
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { FormControlLabel, Switch } from '@mui/material';


import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import ResponsiveDrawer from '../atoms/ResponsiveDrawer';

// firebaseのインポート
import { getDatabase, ref, onValue, push, child, set, serverTimestamp} from "firebase/database";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function TempClose() {

  // 初回レンダリング完了時に実行
  // コンポーネント初期化
  useEffect(() => {
    getRealtimeDB();
  }, []);


  function settingData(day, businessDayFlag, startTime, endTime, comment = "") {
    return {
      day,businessDayFlag,startTime, endTime, comment,
      breakTimes: [
        {
          startTime: hhmm2val(10,0),
          endTime: hhmm2val(11,0),
          comment: 'お昼休憩',
        },
        {
          startTime: hhmm2val(15,0),
          endTime:  hhmm2val(18,0),
          comment: '夕方休憩',
        },
      ],
    };
  }

  const hhmm2val = (hh, mm) => hh*60+mm
  const val2hhmm = value => String(Math.floor(value/60)).padStart(2,'0')+':'+String(value%60).padStart(2,'0')

  var settingsData = [
    settingData('2022-01-11', false, hhmm2val(7,0), hhmm2val(21,0)),
    settingData('2022-01-21', true, hhmm2val(8,0), hhmm2val(20,0)),
    settingData('2022-02-11', true, hhmm2val(9,0), hhmm2val(19,0)),
  ];

  // 編集対象日付設定
  const [day, setDay] = useState(new Date());
  const [settings, setSettings] = useState(settingsData);


  // 臨時休業、臨時営業情報の表示処理
  function getTileContent({ date, view }) {
    if (view === 'month') {
        const targetDate = moment(date).format('YYYY-MM-DD');

        var text = null;
        var d = settings.find((d) => d.day === targetDate);
        //console.log(d);
        if (d) {
            text = d.businessDayFlag ? '臨時' : '休業';
        }
        return <p>{text}</p>
    }
  };

 // カレンダークリックによるカレントの臨時休業、臨時営業情報の切り替え
 const handleCalendar = (value, event) => {

    const targetDate = moment(value).format('YYYY-MM-DD');
    console.log(targetDate);
    setDay(targetDate);

    var d = settings.find((d) => d.day === targetDate);
    if (d) {

      // 登録あり（カレント情報を切り替えて更新処理に進む）
      console.log(d);
      setSwitchValue(d.businessDayFlag)
      setSliderValues([d.startTime, d.endTime]);
    } else {
      // 登録なし（新規登録）
      if (window.confirm('臨時の休業予定 or 営業予定を追加しますか？')) {
        // OKならクリックした日付で新規作成
        let d = settingData(targetDate, false, hhmm2val(7,0), hhmm2val(21,0));
        console.log("new setting", d);

        setSettings([...settings, d,]);

        setSwitchValue(d.businessDayFlag)
        setSliderValues([d.startTime, d.endTime]);

        console.log(settings);
      } else {
        setSwitchValue(false)
      }
    }

  }


  // Switch定義
  const [switchValue, setSwitchValue] = useState(true)

  const handleSwitchChange = (event, newValue) => {

    setSwitchValue(newValue)

    let index = settings.findIndex((s) => s.day === day);
    if (index>=0) {
      console.log(index, settings[index]);

      settings[index].businessDayFlag = newValue;
      setSettings(settings)
    }


  };

  // Slider定義
  const minDistance = 30;

  //const unixTime2javaScriptTime = value => value*1000

  const startOfDay = hhmm2val(7,0)
  const endOfDay   = hhmm2val(25,0)

  const startTime = hhmm2val(9,0)
  const endTime   = hhmm2val(19,0)

  const [sliderValues, setSliderValues] = useState([startTime,endTime])


  const handleSliderChange = (event, newValue, activeThumb) => {

    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setSliderValues([Math.min(newValue[0], sliderValues[1] - minDistance), sliderValues[1]]);
    } else {
      setSliderValues([sliderValues[0], Math.max(newValue[1], sliderValues[0] + minDistance)]);
    }

    let index = settings.findIndex((s) => s.day === day);
    if (index>=0) {
      settings[index].startTime = newValue[0]
      settings[index].endTime = newValue[1]

      setSettings(settings)
    }

  };

  const marks = [
    { value: hhmm2val(7,0), label: '7:00', },
    { value: hhmm2val(10,0),label: '10:00', },
    { value: hhmm2val(13,0),label: '13:00', },
    { value: hhmm2val(16,0),label: '16:00', },
    { value: hhmm2val(19,0),label: '19:00', },
    { value: hhmm2val(22,0),label: '22:00', },
    { value: hhmm2val(25,0),label: '25:00', },
  ];


  const shopid = 'shopid0001';

  // Firebaseから最新データを取得
  const getRealtimeDB = () => {

    const realtimedb = getDatabase();
    const dbRef = ref(realtimedb, 'ShopHours/'+shopid+'/');

    onValue(dbRef, snapshot => {

      if (snapshot) {
        const dataList = snapshot.val();
        //const key = snapshot.key;
        let arrary = [];
        // データオブジェクトを配列に変更する
        if(dataList != null) {
          console.log("getRealtimeDB:")
          Object.keys(dataList).forEach((val, key) => {
              //console.log(val, key)
              dataList[val].id = val;
              arrary.push(dataList[val]);
          })
        }
        console.log(arrary);
        setSettings(arrary);
     }

    });
  }

  // Firebaseへデータ書き込み
  const putRealtimeDB = (data) => {

    const realtimedb = getDatabase();
    const newKey = push(child(ref(realtimedb), 'posts')).key;
    //set(ref(realtimedb, 'ShopHours/'+shopid+'/'+newKey), data);
    set(ref(realtimedb, 'ShopHours/'+shopid+'/'), data);

  };

  const handleUpdate = () => {

    let shopHours = settings.find((s) => s.day === day);
    if (shopHours) {
      console.log(shopHours);
      //putRealtimeDB(shopHours);
      putRealtimeDB(settings);
    }

  };

  return (
    <ResponsiveDrawer>
    <div>
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
              m: 1,
            },
          }}
      >

        <Calendar
              value={new Date()}
              tileContent={getTileContent.bind(this)}
              onChange={handleCalendar}
              // onClickDay={handleCalendar}
              // onClickMonth={handleCalendar}
              locale="ja-JP"
              view='month'
              showDoubleView='true'
              showNavigation='false'
              prevLabel={null}
              prev2Label={null}
              nextLabel={null}
              next2Label={null}
            />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
              m: 1,
            },
            width: 600
          }}
        >
          <Slider
            getAriaLabel={() => 'Minimum distance'}
            value={sliderValues}
            disabled={!switchValue}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            getAriaValueText={val2hhmm}
            valueLabelFormat={val2hhmm}
            disableSwap
            min={startOfDay}
            max={endOfDay}
            marks={marks}
            step={30}
          />
        </Box>

        {/* <Box sx={{ width: 600 }}>
          <Slider
            aria-label="Custom marks"
            defaultValue={20}
            onChange={handleSliderChange}
            getAriaValueText={val2hhmm}
            step={10}
            valueLabelDisplay="auto"
          />
        </Box> */}

        <Box
          component="span"
          m={1}
          sx={{
            box: {
              width: 600,
              height: 500,
              display: "flex",
              border: "1px solid black",
              padding: 8
            },
            spreadBox: {
              justifyContent: "space-around",
              alignItems: "center"
            }
          }}
        >
          <Stack direction="row" spacing={10}>
            <FormControlLabel
              control={<Switch
                        checked={switchValue}
                        onChange={handleSwitchChange}
              />}
              label="営業日"
            />
            <Item>
              <TextField
                id="opentime"
                label="開始"
                type="time"
                defaultValue="07:30"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 30*60, // 5 min
                }}
                value={val2hhmm(sliderValues[0])}
                sx={{ width: 150 }}
              />
            </Item>

            <Item>
              <TextField
                id="closetime"
                label="終了"
                type="text"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 30*60, // 5 min
                }}
                value={ val2hhmm(sliderValues[1]) }
                sx={{ width: 150 }}
              />
            </Item>
          </Stack>
        </Box>
        <Box
          sx={{
          display: 'flex',
          flexDirection: 'column',
          //width: 900,
          // height: 100,
          align: 'center',
          '& > *': {
            m: 1,
          },
        }}
        >
          <Stack direction="row" spacing={10}>
            <Item>
              <Button variant="contained"
                onClick={handleUpdate}
              >
                更新</Button>
            </Item>
            <Item>
              <Button variant="contained">キャンセル</Button>
            </Item>
          </Stack>
        </Box>


    </Box>
    </div>
    </ResponsiveDrawer>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// レスポンシブ・アクション用
import { Menu, } from '@mui/icons-material/';

// アプリメニュー用
import * as Icons from '@mui/icons-material/'
import StorefrontIcon from '@mui/icons-material/Storefront';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SettingsIcon from '@mui/icons-material/Settings';


import { Link, useLocation } from 'react-router-dom';


export const drawerWidth = 180;

// interface Props {
//   window?: () => Window;
//   children: React.ReactNode;
// }

function ResponsiveDrawer(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const routes = [
    { key: 'OPEN', pathname: "/OpenCloseStore", icon: "StorefrontIcon" },
    { key: 'TODAY', pathname: "/Today", icon: "DirectionsRunOutlinedIcon"},
    { key: 'ORDER', pathname: "/Order", icon: "FolderSharedOutlinedIcon" },
  ];

  const { pathname } = useLocation();

  const drawer = (
    <div>
      <Toolbar />

      {/* <List>
        { routes.map(route => (
            <ListItemButton selected={route.pathname === pathname} component={Link} to={route.pathname}>
            <ListItemIcon>
              {Icons[route.icon]}
            </ListItemIcon>
            <ListItemText primary={route.key} />
          </ListItemButton>
        ))}
      </List> */}

      <List>
        <ListItemButton selected={pathname === "/OpenCloseStore" } component={Link} to="/OpenCloseStore">
          <ListItemIcon>
            <StorefrontIcon />
          </ListItemIcon>
          <ListItemText primary="OPEN" />
        </ListItemButton>
        <ListItemButton selected={pathname === "/Today"} component={Link} to="/Today">
          <ListItemIcon>
            <DirectionsRunOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="TODAY" />
        </ListItemButton>
        <ListItemButton selected={pathname === "/Today"} component={Link} to="/Today">
          <ListItemIcon>
            <FolderSharedOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="ORDER" />
        </ListItemButton>
      </List>

      <Divider />
      <List>
        <ListItemButton selected={pathname === "/SubmitMenu"} component={Link} to="/SubmitMenu">
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <ListItemText primary="RECORD" />
          </ListItemButton>
          <ListItemButton selected={pathname === "/ListMenu"} component={Link} to="/ListMenu">
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="MENU" />
          </ListItemButton>
          <ListItemButton selected={pathname === "/ListSubmit"} component={Link} to="/ListSubmit">
            <ListItemIcon>
              <ScheduleSendIcon />
            </ListItemIcon>
            <ListItemText primary="SUBMIT" />
          </ListItemButton>
      </List>

      <Divider />
      <List>
        <ListItemButton selected={pathname === "/Review"} component={Link} to="/Review">
            <ListItemIcon>
              <LocalActivityOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="REVIEW" />
          </ListItemButton>
          <ListItemButton selected={pathname === "/"} component={Link} to="/">
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="CONTACT" />
          </ListItemButton>
          <ListItemButton selected={pathname === "/"} component={Link} to="/">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="SETTING" />
          </ListItemButton>
      </List>

    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            店舗 App
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {props.children}

     </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.object,

  window: PropTypes.func,
};

export default ResponsiveDrawer;

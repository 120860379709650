import React, { useState, useEffect, } from 'react';
import { styled } from '@mui/system'

import { useFormContext } from 'react-hook-form';

import { Grid, Box, } from '@material-ui/core';
import Stack from '@mui/material/Stack';

import { TextField, Button } from '@mui/material';

// For Firebase storage
import { ref, getDownloadURL } from "firebase/storage";
import { firebaseStorage } from '../../Firebase';


//#################################
const shopid = 'shopid0002';

const SubmitMenuContent2 = (props) => {

  const styles = theme => ({

    //const styles = StyleSheet.create({
      textFld: { width: 200, height: 40}   //assign the width as your requirement
    });

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useFormContext();

  console.log('error:', errors);

  // 初回レンダリング完了時に実行
  // コンポーネント初期化
  useEffect(() => {

    // photoW1H1の初期化
    const photoW1H1 = getValues().photoW1H1;
    console.log('photoW1H1:', photoW1H1);

    if (photoW1H1) {
      // パラメータが設定されているなら画像データ取得
      setupFBStorageImage(shopid, photoW1H1, setImage1);
    }

    // photoW2H1の初期化
    const photoW2H1 = getValues().photoW2H1;
    console.log('photoW2H1:', photoW2H1);

    if (photoW2H1) {
      // パラメータが設定されているなら画像データ取得
      setupFBStorageImage(shopid, photoW2H1, setImage2);
    }

  }, []);

  const setupFBStorageImage = (shopid, photoW1H1, setImage) => {
    const imagesRef = ref(firebaseStorage, 'shops/'+shopid+'/submit/');

    // Points to 'images/space.jpg'
    // Note that you can use variables to create child values
    const fileName = photoW1H1+'.jpeg';
    const spaceRef = ref(imagesRef, fileName);

    // Get the download URL
    getDownloadURL(spaceRef)
      .then((url) => {
        // Insert url into an <img> tag to "download"
        console.log('url:',url);
        setImage(url);
        // setImageURL(...imageURL, imageURL[photoW1H1]=url);

      })
  }

  const [image1, setImage1] = useState(getValues('photoW1H1Data'));
  const [image2, setImage2] = useState(getValues('photoW2H1Data'));

  const onFileChange = (e, setImage) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      //console.log('onFileChange(): ', data)
      setImage(data, file);
    };
    reader.readAsDataURL(file);
  }

  const setPhotoW1H1 = (data, file) => {
    setImage1(data);
    setValue('photoW1H1Data', data);
    setValue('photoW1H1File', file);
  }
  const setPhotoW2H1 = (data, file) => {
    setImage2(data);
    setValue('photoW2H1Data', data);
    setValue('photoW2H1File', file);
  }

  const onSubmit = (action) => {
    if(action === 'back') {
        props.handleBack();
    } else if (action === 'next') {
        props.handleNext();
    }
  };

  return (
    <div>
      <Grid container maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2}>
          <Box
            component="form"
            sx={{
              border: '1px black',
              backgroundColor: 'primary.dark',
              width: 200,
              height: 200,
              justifyContent: "center",
              align: "center",
            }}
          >
            <img
              src={image1}
              alt='メニューw1h1画像'
              width='180'
              height='180'
              loading='lazy'
              border='1'
            />
          </Box>
          <Box
            component="form"
            sx={{
              border: '1px',
              backgroundColor: 'primary.dark',
              width: 400,
              height: 200,
              justifyContent: "center",
              align: "center",
            }}
          >
            <img
              src={image2}
              alt='メニューw2h1画像'
              width='360'
              height='180'
              loading='lazy'
              border='1'
            />
          </Box>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, },
            height: 80,
            justifyContent: "center",
          }}
          autoComplete="off"
        >
          <Stack
            direction={'row'}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            >
            <input
              type="file"
              accept="image/*"
              className="inputFileBtnHide"
              onChange={(e) => {
                onFileChange(e, setPhotoW1H1);
              }}
              // {...register("photoW1H1File", {})}
            />

            <input
              type="file"
              accept="image/*"
              className="inputFileBtnHide"
              onChange={(e) => {
                onFileChange(e, setPhotoW2H1);
              }}
              // {...register("photoW2H1File", {})}
            />
          </Stack>
        </Box>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '100ch' },
            //justifyContent: "center",
            height: 150,
          }}
        >
          <TextField type="text" label="詳細" placeholder="店舗の紹介文を入力"
            id="outlined-multiline-static"
            id="outlined-textarea"
            multiline
            rows={4}
            defaultValue=""
            {...register("discription", { maxLength: 200})} />
        </Box>

        <Box
          component="form"
          sx={{
            height: 150,
            justifyContent: "center",
          }} >
        <div>
          <Button variant="contained" color="primary"
            onClick={() => { onSubmit("back"); }} >
            戻る
          </Button>
          <Button variant="contained" color="primary"
            onClick={() => { onSubmit("next"); }} >
            次へ
          </Button>
        </div>
      </Box>

      </form>
    </Grid>

    </div>
  )
};

export default SubmitMenuContent2;


import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'


import ResponsiveDrawer from '../atoms/ResponsiveDrawer'


const styles = theme => ({
  home: {
    //backgroundColor: "red",
    width: "50%"
  },
})


class Home extends React.Component {

  render() {
   const { classes } = this.props;
    return (
      <ResponsiveDrawer>
        <div className={classes.home}>
          <p>未ログイン</p>
          <Button variant="contained" color="secondary">
            登録・ログイン
          </Button>
        </div>
      </ResponsiveDrawer>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
}

//export default withStyles(styles, { withTheme: true })(Home)
export default Home


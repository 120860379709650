import React from "react"

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import moment from 'moment';


// For Firebase Realtime DB
import { ref, onValue} from 'firebase/database';
import { firebaseDB } from '../../Firebase';


import ResponsiveDrawer from '../atoms/ResponsiveDrawer'


function createData(orderid, pickupTime, payAmount, paymentMethod, orderStatus) {
  return {
    orderid,
    pickupTime,
    payAmount,
    paymentMethod,
    orderStatus,
    orderItems: [
      {
        menuid: '1234567',
        menuname: '野菜たっぷりグリーンサラダ',
        dressingOptions: ['ごまドレッシング',],
        count: 3,
      },
      {
        menuid: '1111111',
        menuname: '九条葱と高野豆腐の肉味噌がけ',
        dressingOptions: ['イタリアンドレッシング','シーザードレッシング',],
        count: 1,
      },
    ],
  };
}


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  //console.log(row)

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.orderid}
        </TableCell>
        <TableCell align="center">
          { moment(row.pickupTime).format('HH:mm') }
        </TableCell>
        <TableCell align="center">{row.payAmount}</TableCell>
        <TableCell align="center">{row.paymentMethod}</TableCell>
        <TableCell align="center">{row.orderStatus}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h7" gutterBottom component="div">
                注文時間:               { moment(row.orderDate).format() }<br />
                ピックアップ:  { moment(row.pickupTime).format('HH:mm') }<br />
                ユーザネーム:  aaaaaaaaaa bbbbbbbbbbbb ccccccccccc<br />
                TEL: 080-1234-5678<br />
                オーダー:
              </Typography>
              <Table size="small" aria-label="orderitems">
                <TableHead>
                  <TableRow>
                    <TableCell>メニューid</TableCell>
                    <TableCell>メニュー名</TableCell>
                    <TableCell>ドレッシング</TableCell>
                    <TableCell align="right">count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orderItems.map((historyRow) => (
                    <TableRow key={historyRow.menuid}>
                      <TableCell component="th" scope="row">
                        {historyRow.menuid}
                      </TableCell>
                      <TableCell>{historyRow.menuname}</TableCell>
                      <TableCell>
                        {typeof historyRow.dressingOptions !== 'undefined' &&
                            historyRow.dressingOptions.join("、")
                        }
                      </TableCell>
                      <TableCell align="right">{historyRow.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    orderid: PropTypes.string.isRequired,
    pickupTime: PropTypes.string.isRequired,
    payAmount: PropTypes.number.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    orderStatus: PropTypes.string.isRequired,
    orderItems: PropTypes.arrayOf(
      PropTypes.shape({
        menuid: PropTypes.string.isRequired,
        menuname: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

//var rows = [
  // createData('order_no0001', '16:45', 1234, '店頭', '未完了'),
  // createData('order_no0002', '17:35', 2222, 'クレカ', '未完了'),
  // createData('order_no0003', '17:45', 333, '店頭', '完了'),
  // createData('order_no0004', '16:00', 4444, '店頭', 'キャンセル'),
  // createData('order_no0005', '18:05', 55555, 'クレカ', 'キャンセル'),
  // createData('order_no0006', '18:25', 666, '店頭', 'キャンセル'),
  // createData('order_no0007', '18:45', 7777, '店頭', '完了'),
//];

class TodayOrder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rows: []
    };
    this.getFirebaseDB();
  }

  // Firebaseからのデータ取得
  getFirebaseDB = () => {

    const dbRef = ref(firebaseDB, 'Orders/');
    //#TODO
    //const starCountRef = ref(realtimedb, 'Orders/');
    onValue(dbRef, snapshot => {

      if (snapshot) {
        const dataList = snapshot.val();
        //const key = snapshot.key;
        let arrary = [];
        // データオブジェクトを配列に変更する
        if(dataList != null) {
          Object.keys(dataList).forEach((val, key) => {
              dataList[val].id = val;
              arrary.push(dataList[val]);
          })
        }
        console.log(arrary)
        this.setState({ rows: arrary })
     }

    });

  }

  render() {
    return (
      <ResponsiveDrawer>
      <div>
        <h1>Today's Order 本日の注文</h1>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">注文No.</TableCell>
                <TableCell align="center">ピックアップ時刻</TableCell>
                <TableCell align="center">金額</TableCell>
                <TableCell align="center">決済方法</TableCell>
                <TableCell align="center">ステータス</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rows.map((row) => (
                <Row key={row.orderid} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      </ResponsiveDrawer>
    )
  }
};

export default TodayOrder

import React, { useState, useEffect, }  from 'react';

import { styled } from '@mui/system';


import { Grid, Box } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { Button, ToggleButtonGroup, ToggleButton } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import ResponsiveDrawer from '../atoms/ResponsiveDrawer'
import { drawerWidth } from '../atoms/ResponsiveDrawer';


// For Firestore Database
import { doc, getDoc } from "firebase/firestore";
import { fireStoreDB } from '../../Firebase';

// For Firebase storage
import { ref, getDownloadURL } from "firebase/storage";
import { firebaseStorage } from '../../Firebase';

//#################################
const shopid = 'shopid0001';



const ListMenu = () => {

  // 初回レンダリング完了時に実行
  // コンポーネント初期化
  useEffect(() => {

    // Firestore DBからデータを読み込む
    getFireStore(shopid);

  }, []);

  // メニューステータス定義
  // 'on_service': サービス中
  // 'off_service': サービス停止中

  // データ定義
  const setMenu = (no, type, menuid, photoW1H1, menuname, price, cookingTIme, menustatut) => {
    return {
      no, type, menuid, photoW1H1, menuname, price, cookingTIme, menustatut,
    }
  }

  var rows = [
    // サンプルデータ
    setMenu(1, 'salad_300', 'menuid00001', 'menuid00001_W1H1', '野菜たっぷりグリーンサラダ', 100, 10, 'on_service'),
    setMenu(2, 'salad_500', 'menuid00002', 'menuid00002_W1H1', '水茄子とこくみトマトのリーフ野菜サラダ', 200, 20, 'on_service'),
    setMenu(3, 'salad_300', 'menuid00003', 'menuid00003_W1H1', '野菜たっぷりグリーンサラダ2', 200, 20, 'off_service'),
  ];
  const [menuData, setMenuData] = useState(rows)

  // メニュータイプ設定
  const [foodType, setFoodType] = useState('salad_300')

  // 各ボタンクリック検知ハンドラー
  const handleSwitchType = (event, type) => {
    console.log('handleSwitchType() with type:', type);
    setFoodType(type);
  };

  const labelOfFoodType = [
    {key: 'salad_300', label: '300円サラダ'},
    {key: 'salad_500', label: '500円サラダ'},
    {key: 'set', label: 'セット'},
    {key: 'side_dish', label: '副菜'},
    {key: 'main_dish', label: 'メイン'},
  ];

  // 各ボタンクリックハンドラー
  // const handleType = (index) => {
  //   setFoodType(index);

  //   console.log('handleType() with index:', index);
  // };
  const buttons = [
    labelOfFoodType.map(type => (
      <ToggleButton
        value={type.key}
        // onClick={() => handleType(type.key)}
        variant='contained'
        sx={{
          width: 130,
        }}
      >
        {type.label}
      </ToggleButton>
      )
    )
  ];

  // Firebase Storageからの画像取得
  const imageURLArray = [];
  const [imageURL, setImageURL] = useState([]);
  const [imageAlt, setImageAlt] = useState("");

  const setupFBStorageImage = (shopid, photoW1H1) => {
    const imagesRef = ref(firebaseStorage, '/shops/'+shopid+'/');

    // Points to 'images/space.jpg'
    // Note that you can use variables to create child values
    const fileName = photoW1H1+'.jpeg';
    const spaceRef = ref(imagesRef, fileName);

    // Get the download URL
    getDownloadURL(spaceRef)
      .then((url) => {
        // Insert url into an <img> tag to "download"
        console.log('url:',url);
        imageURLArray[photoW1H1] = url;
        setImageURL(imageURLArray);
        // setImageURL(...imageURL, imageURL[photoW1H1]=url);

        console.log('imageURL:',imageURL);
        console.log('imageURLArray:',imageURLArray);

        // 擬似的にTableの画像セル全体再描画をかける
        // altの文字列を更新
        setImageAlt('最終読込:'+photoW1H1);
      })
      // 連想配列要素初期化
      imageURLArray[photoW1H1] = "";
      setImageURL(imageURLArray);
      // setImageURL(...imageURL, imageURL[photoW1H1]="");
  }


  // Firestoreからのデータ取得
  const getFireStore = async (shopid) => {

    console.log('getFireStore() with shopid:', shopid);

    const ref = doc(fireStoreDB, "menus", shopid);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const newRows = data.theMenuList;
      console.log("Succed in getFireStore() data:", data);
      console.log("New theMenuList:", newRows);

      newRows.map((row) => (
        setupFBStorageImage(shopid, row.photoW1H1)
      ));

      setMenuData(newRows);
      console.log("New rows:", rows);
    } else {
      // doc.data() will be undefined in this case
      console.log('Fail to getFireStore() with shopid:', shopid);
    }

  };

  const styles = theme => ({

    //const styles = StyleSheet.create({
      textFld: { width: 200, height: 40}   //assign the width as your requirement
  });

  return (
    <ResponsiveDrawer>
      <div>
        {/* <p>TOP</p> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'center',
            '& > *': {
              m: 2,
            },
            height: 500,
            width: 910-drawerWidth
          }}
        >
          <Stack spacing={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height:60,
                '& > *': {
                  m: 10,
                },
              }}
            >
              <ToggleButtonGroup
                value={foodType}
                exclusive
                onChange={handleSwitchType}
                size="large"
                color="standard"
                aria-label="large button group">
                {buttons}
              </ToggleButtonGroup>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: 900-drawerWidth,
                '& > *': {
                  m: 1,
                },
              }}
            >
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">No.</TableCell> */}
                      <TableCell align="center" style={{ width: 100 }}>画像</TableCell>
                      <TableCell align="center" style={{ width: 300 }}>メニュー名</TableCell>
                      <TableCell align="center" style={{ width: 120 }}>価格</TableCell>
                      <TableCell align="center" style={{ width: 120 }}>所要時間</TableCell>
                      <TableCell align="center" style={{ width: 150 }}>ステータス</TableCell>
                      <TableCell align="center" style={{ width: 50 }}>詳細</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {menuData.map((row) => (
                      (row.type === foodType) ?
                      <TableRow
                        key={row.menuid}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {/* <TableCell align="center" component="th" scope="row">{###連番実装}</TableCell> */}
                        <TableCell>
                          <img　src={`${imageURL[row.photoW1H1]}?w=164&h=164&fit=crop&auto=format`}
                                width="100" height="100" alt={imageAlt} loading='lazy' />
                        </TableCell>
                        <TableCell align="left">{row.menuname}</TableCell>
                        <TableCell align="center">{row.price}</TableCell>
                        <TableCell align="center">{row.cookingTIme}</TableCell>
                        <TableCell align="center">{row.menustatut}</TableCell>
                        <TableCell align="center">
                          <Button variant="contained">編集</Button>
                        </TableCell>
                      </TableRow>
                      : null
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            </Stack>

        </Box>
      </div>
    </ResponsiveDrawer>
  )

}

export default ListMenu;


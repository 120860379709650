import React, { useState, }  from 'react';
import { useNavigate } from "react-router-dom";

import { useFormContext } from 'react-hook-form';

import { Grid } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SubmitMenuContent1 from './SubmitMenuContent1';
import SubmitMenuContent2 from './SubmitMenuContent2';
import SubmitMenuContent3 from './SubmitMenuContent3';
import SubmitMenuConfirm from './SubmitMenuConfirm';


const SubmitMenuStepper = () => {

  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useFormContext();

  const [activeStep, setActiveStep] = useState(0);

  const getSteps = () => {
    return [
      'フォーム1',
      'フォーム2',
      'フォーム3',
      '確認',
    ];
  }
  const steps = getSteps();

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <SubmitMenuContent1 handleNext={handleNext}  handleBack={handleBack} />;
      case 1:
        return <SubmitMenuContent2 handleNext={handleNext}  handleBack={handleBack} />;
      case 2:
        return <SubmitMenuContent3 handleNext={handleNext}  handleBack={handleBack} />;
      case 3:
        return <SubmitMenuConfirm handleNext={handleNext}  handleBack={handleBack} />;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    if (activeStep===0) {
      // navigate(-1);
      navigate('/ListSubmit');
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const styles = theme => ({

    //const styles = StyleSheet.create({
      textFld: { width: 200, height: 40}   //assign the width as your requirement
  });

  return (
    <Grid container>
      <Grid lg={8} sm={8} spacing={10}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
        {activeStep === steps.length ? (
            <div>
                <Typography >メニュー申請完了</Typography>

                <Button onClick={handleReset}>次のメニューを登録</Button>
            </div>
        ) : (
            <div>
                <Typography >{getStepContent(activeStep, handleNext, handleBack)}</Typography>
                {/* <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    戻る
                </Button>

                {activeStep === steps.length - 1 ? (
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        メニュー申請
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        次へ
                    </Button>
                )} */}
            </div>
        )}
      </Grid>
    </Grid>
    )
};

export default SubmitMenuStepper;

import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { styled } from '@mui/system';

import { FormProvider, useForm, useFormContext } from "react-hook-form";


import { Grid, Box, } from '@material-ui/core';

import ResponsiveDrawer from '../atoms/ResponsiveDrawer';

import SubmitMenuStepper from './SubmitMenuStepper';



const SubmitMenu = () => {

  const location = useLocation();
  console.log(location);

  const data = location.state;
  console.log(data);

  const defaultData = (data===null) ? {
    // props dataがnullの場合には新規作成向けの初期値を設定
    //#TODO DBから取得したデータを設定
    menuname: "最大20文字までのメニュー名を設定します。",
    menutype: 'salad_500',
    dressing1: 'ドレッシング1を設定',
    dressing2: 'ドレッシング22222',
    dressing3: 'ドレッシング333333',
    price: 12345,
    cooking_time: 10,

    intakes: [{name: "摂取量セル初期化", grams: 123},],
    // intakes: [],

    discription: 'メニュー詳細を設定します。',
    allergens: {
      gluten: false,
      soba: true,
      egg: false,
      milk: false,
      peanut: false,
      shrimp: false,
      crab: false,
    },
    memo: '申請時のメモを入力します。',

  } : data;
  console.log(defaultData);

  const methods = useForm({
      mode: 'onChange',
      defaultValues: defaultData,
    });

  const styles = theme => ({

    //const styles = StyleSheet.create({
      textFld: { width: 200, height: 40}   //assign the width as your requirement
  });

  return (
    <ResponsiveDrawer>
      <FormProvider {...methods}>
        <SubmitMenuStepper />
      </FormProvider>
    </ResponsiveDrawer>
  )

};
export default SubmitMenu;


import React, { useState } from 'react';
import { auth } from '../../Firebase';
import { signInWithEmailAndPassword } from "firebase/auth";

import { Link, useNavigate } from 'react-router-dom';


const Login = () => {

  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {

    event.preventDefault();
    const { email, password } = event.target.elements;
    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);

      console.log('Succed in Login:', email.value);
      navigate('/');
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  return (
    <div>
      <h1>ログイン</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>メールアドレス</label>
          <input name="email" type="email" placeholder="email" />
        </div>
        <div>
          <label>パスワード</label>
          <input name="password" type="password" placeholder="password" />
        </div>
        <div>
          <p><font color='red'>{error}</font></p>
          <button>ログイン</button>
        </div>
        <div>
          {/* ユーザ登録は<Link to={'/Signup'}>こちら</Link>から */}
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { useState } from 'react';
import { styled } from '@mui/system'

import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { Grid, Box, } from '@material-ui/core';
import Stack from '@mui/material/Stack';

import { TextField, RadioGroup, Radio, Checkbox, Button } from '@mui/material';

import { FormControl , FormGroup, FormControlLabel, FormLabel, } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';


import { getStorage, ref, uploadBytesResumable, getDownloadURL, resolve } from "firebase/storage";
import { firebaseStorage } from '../../Firebase';


import { uploadImage, getNewMenuid, addMenuData, updateMenuData } from './SubmitMenuPerform';


const shopid = 'shopid0002';

const SubmitMenuConfirm = (props) => {

    const styles = theme => ({

      //const styles = StyleSheet.create({
        textFld: { width: 200, height: 40},   //assign the width as your requirement
        justifyContent: "center",
      });

    const { register, handleSubmit, formState: { errors }, getValues } = useFormContext();


    console.log('error:', errors);

    const onClick = (action) => {
      if(action === 'back') {
          props.handleBack();
      // } else if (action === 'next') {
      //     props.handleNext();
      }
    };

    const onSubmit = data => {
      console.log('Submit data:', data);
      console.log('error:', errors);

      var menuid = data.menuid;
      if (getValues('menuid') === undefined) {
        // 新規メニューレコード作成
        console.log('undefined menuid:', menuid);

        getNewMenuid(shopid).then((menuid)=>{
          //setValue('menuid', menuid);
          data.menuid = menuid;

          data.photoW1H1 = menuid+'_W1H1';
          uploadImage(data.photoW1H1File, shopid, data.photoW1H1+'.jpeg');
          delete data.photoW1H1Data;
          delete data.photoW1H1File;

          data.photoW2H1 = menuid+'_W2H1';
          uploadImage(data.photoW2H1File, shopid, data.photoW2H1+'.jpeg');
          delete data.photoW2H1Data;
          delete data.photoW2H1File;

          addMenuData(data, shopid, menuid);
        });

      } else {
        // 既存メニューレコード更新
        console.log('menuid:', menuid);

        // アップロードファイルが選択されていたら既存画像を上書き
        if (data.photoW1H1File) {
          data.photoW1H1 = menuid+'_W1H1';
          uploadImage(data.photoW1H1File, shopid, data.photoW1H1+'.jpeg');
        }
        delete data.photoW1H1Data;
        delete data.photoW1H1File;

        // アップロードファイルが選択されていたら既存画像を上書き
        if (data.photoW2H1File) {
          data.photoW2H1 = menuid+'_W2H1';
          uploadImage(data.photoW2H1File, shopid, data.photoW2H1+'.jpeg');
        }
        delete data.photoW2H1Data;
        delete data.photoW2H1File;

        updateMenuData(data, shopid, menuid);
      }

      // 最終完了画面に移動
      props.handleNext();
    };

    return (
      <div>
        <Grid container maxWidth="sm">

        <form onSubmit={handleSubmit(onSubmit)}>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100ch' },
              justifyContent: "center",

              height: 200,
            }}
          >
            <TextField type="text" label="申請メモ" placeholder="申請メモ"
              id="outlined-multiline-static"
              id="outlined-textarea"
              multiline
              rows={4}
              defaultValue=""
              //value={}
              // onChange={alert("")}
              {...register("memo", { maxLength: 2000})} />
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, },
              height: 280,
              justifyContent: "center",
            }}
            autoComplete="off"
          >
            <Stack
              direction={'row'}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              >

            </Stack>
          </Box>


          <Box
            component="form"
            sx={{
              height: 150,
              justifyContent: "center",
            }}
          >
            <div>
              <Button variant="contained" color="primary"
                onClick={() => { onClick("back"); }} >
                戻る
              </Button>
              <Button variant="contained" color="primary"
                onClick={() => { onSubmit(getValues()); }} >
                メニュー申請
            </Button>
            </div>
          </Box>

        </form>

      </Grid>

      </div>
    )
  };

export default SubmitMenuConfirm;


import React, { useState } from 'react';
import { styled } from '@mui/system'

import { FormProvider, useForm, useFormContext, Controller, } from 'react-hook-form';

import { Grid, Box, } from '@material-ui/core';
import Stack from '@mui/material/Stack';

import { TextField, RadioGroup, Radio, Checkbox, Button } from '@mui/material';

import { FormControl , FormGroup, FormControlLabel, FormLabel, } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';


const SubmitMenuContent1 = (props) => {

  const { register, handleSubmit, formState: { errors }, control, getValues } = useFormContext();

  console.log('error:', errors);

  const styles = theme => ({

    //const styles = StyleSheet.create({
      textFld: { width: 300, height: 40 }   //assign the width as your requirement
    });

  const onSubmit = (action) => {
    if(action === 'back') {
      props.handleBack();
    } else if (action === 'next') {
      props.handleNext();
    }
  };

  const options = [
    { key: 'salad_300', title: "300円サラダ" },
    { key: 'salad_500', title: "500円サラダ" },
    { key: 'set',       title: "セット" },
    { key: 'side_dish', title: "副菜" },
    { key: 'main_dish', title: "メイン" },
  ];

  const defaultPrice = (getValues().price)? getValues().price: 0
  const [priceValue, setPriceValue] = useState(defaultPrice);


  return (
    <div>
      <Grid container maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
      {/* <form > */}
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 50, width: '50ch' },
            height: 120,
            justifyContent: "center",
          }}
          // noValidate
          autoComplete="off"
        >
        <Grid container>
          <Grid item xs={6}>
            <FormControl component="standard">
              <FormLabel component="legend">メニュー名:</FormLabel>
                <TextField
                  required
                  style = {styles.textFld}
                  id="outlined-required"
                  label="メニュー名"
                  margin="normal"
                  {...register("menuname", {required: true, maxLength: 20})}
                  // defaultValue=""
                />
                {errors.menuname?.type === 'required' && "メニュー名は必須です"}
                {errors.menuname?.type === 'maxLength' && "メニュー名は最大20文字までです"}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '500ch' },
            width: 1000,
            height: 150,
            justifyContent: "center",
            border: '1px',
          }}
          // noValidate
          autoComplete="off"
        >
          <FormControl component="outlined">
            <FormLabel component="legend">カテゴリー選択:</FormLabel>
            <Controller
              name="menutype"
              control={control}
              rules={{ required: "選択してください。" }}
              // defaultValue="salad_300"
              render={({ field, fieldState }) => (
                <RadioGroup {...field}>
                  {<Grid container>
                    {options.map((option, i) => (
                      <Grid item xs={4}>
                        <FormControlLabel
                          value={option.key}
                          control={<Radio />}
                          label={option.title}
                          key={option.key}
                        />
                      </Grid>
                    ))}
                  </Grid>}
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '300' },
            height: 150,
            justifyContent: "center",

          }}
          // noValidate
          autoComplete="off"
        >
          <FormControl component="fieldset"
            // fullWidth
            >
            <FormLabel component="legend">ドレッシング:</FormLabel>
              <Grid container>
              <Stack
                direction={'row'}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  <Grid item xs={4}>
                    <TextField
                      required
                      style = {styles.textFld}
                      id="standard-helperText"
                      label="ドレッシング1を入力"
                      variant="standard"
                      margin="normal"
                      {...register("dressing1", {maxLength: 100})}
                      // defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                        required
                        style = {styles.textFld}
                        id="standard-helperText"
                        label="ドレッシング2を入力"
                        variant="filled"
                        margin="normal"
                        {...register("dressing2", {maxLength: 100})}
                        // defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                          required
                          style = {styles.textFld}
                          id="standard-helperText"
                          label="ドレッシング3を入力"
                          variant="outlined"
                          margin="normal"
                          {...register("dressing3", {maxLength: 100})}
                          // defaultValue=""
                    />
                  </Grid>
                </Stack>
              </Grid>
          </FormControl>
        </Box>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, },
            height: 80,
            justifyContent: "center",
          }}
          autoComplete="off"
        >
          <Stack
            direction={'row'}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            >
            <TextField
              id="outlined-number"
              label="価格 ※100円刻み"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("price", {required: true, max: 10000, min: 100})}
              value={priceValue}
              onChange={(event) => { setPriceValue(Math.ceil(event.target.value/100)*100) }}
            />

            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-weight"
                //label="所要 (調理) 時間"
                // value={values.weight}
                // onChange={handleChange('weight')}
                endAdornment={<InputAdornment position="end">分</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                type="number"
                {...register("cooking_time", { max: 30, min: 3})}
              />
                <FormHelperText id="outlined-weight-helper-text">所要 (調理) 時間</FormHelperText>
              </FormControl>
          </Stack>
        </Box>

        <Box
            component="form"
            sx={{
              height: 350,
              // justifyContent: "center",
            }} >
          <Grid container>
            <Grid lg={12} sm={12} spacing={10}>
              <div>
                <Button variant="contained" color="primary"
                  onClick={() => { onSubmit("back"); }} >
                  戻る
                </Button>
                <Button variant="contained" color="primary"
                  // type="submit" >
                  onClick={() => { onSubmit("next"); }} >
                  次へ
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>

      </form>
    </Grid>
    </div>
  )
};
export default SubmitMenuContent1;

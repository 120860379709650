import React, { useState, useEffect, } from 'react';
import { styled } from '@mui/system'

import { useFormContext, Controller } from 'react-hook-form';

import { Grid, Box, } from '@material-ui/core';

import { Checkbox, Button } from '@mui/material';

import { FormControl , FormGroup, FormControlLabel, FormLabel, } from '@mui/material';

import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import { AddBox } from "@material-ui/icons";
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';



const SubmitMenuContent3 = (props) => {

  //const styles = theme => ({
  const styles = () => ({

    //const styles = StyleSheet.create({
      textFld: { width: 200, height: 40}   //assign the width as your requirement
    });

  const { register, handleSubmit, formState: { errors }, getValues, setValue, control } = useFormContext();


  // 摂取量データ
  var intakesData = [
    { number: 1, name: "　", grams: 0 },
    { number: 2, name: "　", grams: 0 },
    { number: 3, name: "　", grams: 0 },
    { number: 4, name: "　", grams: 0 },
    { number: 5, name: "　", grams: 0 },
    { number: 6, name: "　", grams: 0 },
    { number: 7, name: "　", grams: 0 },
    { number: 8, name: "　", grams: 0 },
    { number: 9, name: "　", grams: 0 },
    { number: 10, name: "　", grams: 0 },
    { number: 11, name: "　", grams: 0 },
    { number: 12, name: "　", grams: 0 },
    { number: 13, name: "　", grams: 0 },
    { number: 14, name: "　", grams: 0 },
    { number: 15, name: "　", grams: 0 },
    { number: 16, name: "　", grams: 0 },
    { number: 17, name: "　", grams: 0 },
    { number: 18, name: "　", grams: 0 },
    { number: 19, name: "　", grams: 0 },
    { number: 20, name: "調味料", grams: 0 },
  ];
  const [intakesDataList, setIntakesDataList] = useState(intakesData);

  // アレルゲン・チェックボックス群データ
  const [allergens, setAllergens] = useState(
      { allergens: {
        gluten: false,
        soba: true,
        egg: false,
        milk: false,
        peanut: false,
        shrimp: false,
        crab: false,
      },}
    );

  // 初回レンダリング完了時に実行
  // コンポーネント初期化
  useEffect(() => {

    // 摂取量テーブル初期化
    const intakes = getValues().intakes;
    console.log('intakes:', intakes);

    if (intakes) {
      intakes.forEach((intake, index) => {
        intakesData[index].name = intake.name;
        intakesData[index].grams = intake.grams;
      });
    }
    setIntakesDataList(intakesData);
    console.log('setIntakesDataList()', intakesData);

    // アレルゲン・チェックボックス群初期化
    setAllergens(getValues().allergens);
    console.log('allergens:', allergens);

  }, []);

  const vegeEatsClumnList = [
    { title: "No.", field: "number", cellStyle: { cellWidth: '15%' }, editable: "never" },
    { title: "名称", field: "name", cellStyle: { cellWidth: '70%' } },
    { title: "摂取量(g)", field: "grams", cellStyle: { cellWidth: '15%' } },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  };

  console.log('error:', errors);

  // const [image, setImage] = useState("");

  // const [imageUrl, setImageUrl] = useState("");
  // console.log('imageUrl:', imageUrl);

  // const handleImage = event => {
  //   const image = event.target.files[0];
  //   setImage(image);
  // };

  const onSubmit = (action) => {
    if(action === 'back') {
        props.handleBack();
    } else if (action === 'next') {
        // 摂取量データ更新
        const newIntakes = intakesDataList.map((intake, index) => {
          if (!intake.name.match(/\S/g) || intake.grams === "0" || intake.grams === 0) {
          } else {
            return { name: intake.name, grams: intake.grams };
          }
        }).filter(x => x);
        console.log('newIntakes:', newIntakes);
        setValue('intakes', newIntakes);

        props.handleNext();
    }
  };


  return (
    <div>
      <Grid container maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, },
            height: 0,
            justifyContent: "center",
          }}
          autoComplete="off"
        >
        </Box>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 4, width: '200ch' },
            width: 1000,
            height: 120,
            justifyContent: "center",
          }}
          autoComplete="off"
        >
          <FormControl component="allergen"
              fullWidth
          >
            <FormLabel component="legend">アレルゲン:</FormLabel>
            <FormGroup>
            <Grid container>
              <Grid item xs={2}>
                <Controller name="allergens.gluten" control={control}
                  render={({ field }) => <Checkbox  checked={allergens.gluten} {...field} />} />
                小麦
              </Grid>
              <Grid item xs={2}>
                <Controller name="allergens.soba" control={control}
                  render={({ field }) => <Checkbox checked={allergens.soba} {...field} />} />
                そば
              </Grid>
              <Grid item xs={2}>
                <Controller name="allergens.egg" control={control}
                  render={({ field }) => <Checkbox checked={allergens.egg} {...field} />} />
                卵
              </Grid>
              <Grid item xs={2}>
                <Controller name="allergens.milk" control={control}
                  render={({ field }) => <Checkbox checked={allergens.milk} {...field} />} />
                牛乳
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2}>
                <Controller name="allergens.peanut" control={control}
                  render={({ field }) => <Checkbox checked={allergens.peanut} {...field} />} />
                落花生
              </Grid>
              <Grid item xs={2}>
                <Controller name="allergens.shrimp" control={control}
                  render={({ field }) => <Checkbox checked={allergens.shrimp} {...field} />} />
                えび
              </Grid>
              <Grid item xs={2}>
                <Controller name="allergens.crab" control={control}
                  render={({ field }) => <Checkbox checked={allergens.crab} {...field} />} />
                かに
              </Grid>
            </Grid>
            </FormGroup>
          </FormControl>

        </Box>

        <MaterialTable
          columns={vegeEatsClumnList}
          data={intakesDataList}
          title="素材入力"
          icons={tableIcons}
          options={{
            search: false,
            paging: false,
            maxBodyHeight: 300,
            headerStyle: { position: 'sticky', top: 0 },
            tableLayout: 'fixed'
          }}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                console.log('Updated table cell:', newValue, rowData, columnDef);

                rowData[columnDef.field] = newValue;
                setIntakesDataList(intakesDataList);

                console.log('new Data:', newValue, rowData, columnDef);
                console.log('new list:', intakesDataList);

                setTimeout(resolve, 1000);
              });
            },
          }}
        ></MaterialTable>

        <Box
          component="form"
          sx={{
            height: 150,
            justifyContent: "center",
          }} >
          <div>
          <Button variant="contained" color="primary"
              onClick={() => { onSubmit("back"); }} >
              戻る
            </Button>
            <Button variant="contained" color="primary"
              onClick={() => { onSubmit("next"); }} >
              次へ
            </Button>

          </div>
        </Box>

      </form>

    </Grid>

    </div>
  )
};

export default SubmitMenuContent3;


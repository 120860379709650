import React from "react"

import { styled } from '@mui/system'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { CardActionArea } from "@mui/material"
import { CardMedia } from "@mui/material"

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'

import { Link } from "react-router-dom"

import ResponsiveDrawer from '../atoms/ResponsiveDrawer'


const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(10),
  },
  grid: {
    margin: "auto",
    border: 0
  },
  button: {
    marginTop: "auto",
    marginBottom: theme.spacing(5),
    width: "300px",
    height: "250px",
    //fontSize: "120px",
    margin: theme.spacing(1),
  },
}));

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>

);


const OpenCloseToggle = (
  <React.Fragment>
  <Card sx={{ maxWidth: 500 }}>
  <CardActionArea>
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        Open<br />
        /<br />
        Close
      </Typography>
      <Typography variant="body2" color="text.secondary">
        ここに説明コメントを複数行にわたって記載することができます。<br />ここに説明コメントを複数行にわたって記載することができます。
        ここに説明コメントを複数行にわたって記載することができます。
      </Typography>
    </CardContent>
    </CardActionArea>
  </Card>
  </React.Fragment>
)

function BodyCard(props) {
  const { avatarUrl, title, subheader, text, imageUrl } = props;
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  return (
      <Card variant="outlined">
          <CardHeader
              avatar={<Avatar src={avatarUrl} />}
              action={
              <IconButton aria-label="settings">
                  <StarBorderOutlinedIcon />
              </IconButton>
              }
              title={title}
              subheader={subheader}
          />
          <CardMedia style={{ height: "150px" }} image={imageUrl} />
          <CardContent>
          <Typography variant="body2" component="p">
              {text}
          </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">詳細をみる</Button>
          </CardActions>
      </Card>
  );
}


const Item = styled(Paper)(({ theme }) => ({
  //...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  //color: theme.palette.text.secondary,
}))


export default function OpenCloseStore(props) {
  const classes = useStyles()

  return (
    <ResponsiveDrawer>
      <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          {/* {OpenCloseToggle} */}
          <Button
              color="primary"
              className={classes.button}
            >
            Open<br />
            /<br />
            Close
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
                variant="contained"
                color="primary"
                className={classes.button}
                component={Link}
                to="/ShopHour">
              曜日毎の営業時間設定
              </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
                variant="contained"
                color="primary"
                className={classes.button}
                component={Link}
                to="/TempClose">
              休業日<br />営業時間変更<br />設定
              </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
                color="primary"
                className={classes.button}
              >
            公開中
            </Button>
        </Grid>
    </Grid>

      {/* <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
          {OpenCloseToggle}
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/Today">
          曜日毎の営業時間設定
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to="/ShopHour">
          休業日<br />営業時間変更<br />設定
          </Button>
          <BodyCard
              title="タイトル１"
              subheader="サブヘッダー１"
              avatarUrl="https://joeschmoe.io/api/v1/random"
              imageUrl="https://picsum.photos/150"
              text="カードの説明１" />
    </Box> */}
    </div>
  </ResponsiveDrawer>
  )
}


import { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../Firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export function useAuthContext() {
//w  console.log('useAuthContext()=', AuthContext)
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState('');

  const value = {
    user,
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log('onAuthStateChanged()=', user)
        setUser(user);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    // const unsubscribed = auth.onAuthStateChanged((user) => {
    //   setUser(user);
    // });
    // return () => {
    //   unsubscribed();
    // };

  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

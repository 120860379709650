import React from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './components/AuthContext';

// ホーム画面
import Home from "./components/pages/HomePage";

// 開閉店設定画面
import OpenCloseStore from "./components/pages/OpenCloseStore";
import ShopHour from "./components/pages/ShopHourSetting";
import TempClose from "./components/pages/TemporaryClosing";


// 現在の注文画面
import TodayOrder from "./components/pages/TodayOrder";


// メニュー申請
import ListMenu from "./components/pages/ListMenu";
import ListSubmit from "./components/pages/ListSubmit";
import SubmitMenu from "./components/pages/SubmitMenu";

// レビュー
import Review from "./components/pages/Review";

import SignUp from './components/SignUp';
import Login from './components/pages/Login';


import { AuthProvider } from './components/AuthContext';
import PrivateRoute from './components/PrivateRoute';



// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const { user } = useAuthContext();
//   return (
//     <Route
//       {...rest}
//       render={(routeProps) => {
//         return user ? <Component {...routeProps} /> : <Navigate to="/login" />;
//       }}
//     />
//   );
// };

const App = () => {
  const { user } = useAuthContext();
  console.log('User Authentication:', user);

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={user? <OpenCloseStore /> : <Navigate to='/Login' />} />

          <Route path="/Login" element ={<Login />} />

          <Route path="/OpenCloseStore" element={user? <OpenCloseStore /> : <Navigate to='/Login' />} />
          <Route path="/ShopHour" element={user? <ShopHour /> : <Navigate to='/Login' />} />
          <Route path="/TempClose" element={user? <TempClose /> : <Navigate to='/Login' />} />

          <Route path="/Today" element={user? <TodayOrder /> : <Navigate to='/Login' />} />
          {/* <Route path="/Today" element={user? <SubmitMenu /> : <TodayOrder />} /> */}

          <Route path="/ListMenu" element={user? <ListMenu /> : <Navigate to='/Login' />} />
          <Route path="/ListSubmit" element={user? <ListSubmit /> : <Navigate to='/Login' />} />
          <Route path="/SubmitMenu" element={user? <SubmitMenu /> : <Navigate to='/Login' />} />

          <Route path="/Review" element={user? <Review /> : <Navigate to='/Login' />} />

        </Routes>
      </BrowserRouter>
  )
}

export default App;


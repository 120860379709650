// Firebaseアクセス・セットアップ
import { initializeApp } from 'firebase/app';
//import { getDatabase, ref, onValue, serverTimestamp} from "firebase/database";


import { getFirestore } from "firebase/firestore";
import { getDatabase } from 'firebase/database';
import { getStorage } from "firebase/storage";

import { getAuth } from "firebase/auth";


// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_SENDER_ID,
// };
const firebaseConfig = {
  apiKey: "AIzaSyD81Vc39XKXri9OQrCekN5MrPzTUhmbX3Y",
  authDomain: "newtricotest.firebaseapp.com",
  databaseURL: "https://newtricotest-default-rtdb.firebaseio.com",
  projectId: "newtricotest",
  storageBucket: "newtricotest.appspot.com",
  messagingSenderId: "1062784072620",
  appId: "1:1062784072620:web:8f5904cc16bd3cdc8a1809"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
export const fireStoreDB = getFirestore(firebaseApp);

// Initialize Firebase Realtime DB
export const firebaseDB = getDatabase();

// Initialize Firebase Storage
export const firebaseStorage = getStorage();

// Initialize Firebase Authentication
export const auth = getAuth();
